<!-- eslint-disable vue/html-closing-bracket-newline -->
<template>
  <div>
    <b-card>
      <b-tabs content-class="mt-2" justified>
        <b-tab active title="Latest Page">
          <b-card-text>
            <LatestPageVue />
          </b-card-text>
        </b-tab>
        <b-tab title="News">
          <PageNewsVue />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import { BTabs, BTab, BCard, BCardText, BLink } from "bootstrap-vue";
import LatestPageVue from "./partials/LatestPage.vue";
import PageNewsVue from "./partials/PageNews.vue";

export default {
  components: {
    BCard,
    BCardText,
    LatestPageVue,
    PageNewsVue,
    BLink,
    BTabs,
    BTab,
  },
};
</script>

<style></style>
